import React, { useEffect, useState } from "react";
import './Checkbox.scss';

const Checkbox = ({ question, onChange, getContent, dam, disabled=false }) => {
    const [value, setValue] = useState(getContent(question.id.split("."), 0, dam) !== undefined ? getContent(question.id.split("."), 0, dam) : []);

    const onCheckboxChange = (e) => {
        if (e.target.checked) {
            let found = false;
            for (let i in value) {
                if (value[i] === e.target.value) found = true;
            }

            if (!found) setValue(value => [...value, e.target.value]);
        } else {
            let newValue = [];
            for (let i in value) {
                if (value[i] !== e.target.value) newValue.push(value[i]);
            }

            setValue(newValue);
        }
    }

    useEffect(() => {
        const checkboxes = document.getElementsByClassName(question.id.replace('.', '-'));

        for (let checkbox in checkboxes) {
            if(value.includes(checkboxes[checkbox].value)) {
                checkboxes[checkbox].checked = true;
            }
        };

        onChange(question.id, value);
    }, []);

    useEffect(() => {
        onChange(question.id, value);
    }, [value]);

    const options = question.options.map((option, index) =>
        <div className="checkbox-option" key={index}>
            <input
                type="checkbox"
                id={`${question.id}-${option.label}`}
                name={question.id}
                value={option["value"]}
                onChange={(e) => onCheckboxChange(e)}
                className={`check ${question.id.replace('.', '-')}`}
                disabled={disabled}
            />
            <label
                className="option"
                htmlFor={`${question.id}-${option["label"]}`}>
                {option["label"]}
            </label>
        </div>
    )

    return (
        <div className="checkbox-options">
            {options}
        </div>
    );
}

export default Checkbox;