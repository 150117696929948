import React from "react";
import './styles.scss';

const Footer = ({ messages }) => {
    if (messages)
        return (
            <div className="how-it-works">
                < div className="how-it-works-content" >
                    <h2 className="title">{messages.howItWorks.title}</h2>
                    <p className="text">
                        {messages.howItWorks.explanation}
                    </p>
                    <div className="legend-container">
                        <div className="legend">
                            <div className={"evaluation level-1"}></div>
                            <h3 className="legend-label number">1</h3>
                            <p className="legend-label">{messages.legend.eval1}</p>
                        </div>

                        <div className="legend">
                            <div className={"evaluation level-2"}></div>
                            <h3 className="legend-label number">2</h3>
                            <p className="legend-label">{messages.legend.eval2}</p>
                        </div>

                        <div className="legend">
                            <div className={"evaluation level-3"}></div>
                            <h3 className="legend-label number">3</h3>
                            <p className="legend-label">{messages.legend.eval3}</p>
                        </div>

                        <div className="legend">
                            <div className={"evaluation level-4"}></div>
                            <h3 className="legend-label number">4</h3>
                            <p className="legend-label">{messages.legend.eval4}</p>
                        </div>

                        <div className="legend">
                            <div className={"evaluation level-5"}></div>
                            <h3 className="legend-label number">5</h3>
                            <p className="legend-label">{messages.legend.eval5}</p>
                        </div>
                    </div>
                    <p className="text">
                        {messages.howItWorks.disclaimer}
                    </p>
                </div >
            </div >
        );

    return (
        <div className="empty-footer" />
    );
}

export default Footer;