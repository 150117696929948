import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Form, Button, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faToggleOn,
    faToggleOff,
    faEye,
    faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import "./Login.scss";
import Text from "./Text";
import GermanLogo from "../../assets/img/de-zasa-logo.svg";
import EnglishLogo from "../../assets/img/en-zasa-logo.svg";
import { loginUser } from "../../services/UserService";
import Footer from "../../components/Footer";
import { Link, useHistory } from "react-router-dom";

const Login = ({ loginTexts, footerTexts, locale }) => {
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    let [loginInfo, setLoginInfo] = useState({
        login: "",
        password: "",
        remember: false,
    });

    const onChange = (field, event) => {
        setLoginInfo({ ...loginInfo, [field]: event.target.value });
    };

    const handleRememberChange = () => {
        setLoginInfo({ ...loginInfo, remember: !loginInfo.remember });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        loginUser(loginInfo.login, loginInfo.password).then((response) => {
            if (response.ok)
                return response.json();
            else
                setShowErrorMessage(true);
        }).then((response) => {
            localStorage.setItem("token", response.token);
            history.push('/');
        }).catch((error) => {
            console.log(error);
        });
    };

    const About = () => {
        const texts = [].map((text) => (
            <Text
                className="about-area"
                title={text.title}
                content={text.content}
            />
        ));
        return texts;
    };

    return (
        <div fluid="true" className="login-page-wrapper">
            <Col className="login-container">
                <div className="login-row">
                    <div className="login-top-logo">
                        {locale === "de" ? <img src={GermanLogo} width="188px" /> : <img src={EnglishLogo} width="188px" />}
                    </div>
                    <Form
                        className="login-form-wrapper"
                        onSubmit={handleSubmit}
                    >
                        {showErrorMessage && (
                            <div className="login-form-error-msg">
                                <span>{loginTexts.loginError}</span>
                                <div className="hide-error-msg-btn">×</div>
                            </div>
                        )}
                        <Form.Group
                            className="login-form-row"
                            controlId="formBasicEmail"
                        >
                            <Form.Control
                                required
                                type="email"
                                placeholder={loginTexts.email}
                                onChange={(e) => onChange("login", e)}
                            />
                        </Form.Group>
                        <InputGroup className="login-form-row">
                            <Form.Control
                                required
                                type={showPassword ? "text" : "password"}
                                placeholder={loginTexts.password}
                                onChange={(e) => onChange("password", e)}
                            />
                            <InputGroup.Text className="show-pswd-btn">
                                <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                />
                            </InputGroup.Text>
                        </InputGroup>
                        {/* 
                        <Form.Group className="login-check">
                            <span> {loginTexts.rememberMe} </span>
                            <FontAwesomeIcon
                                icon={
                                    loginInfo.remember
                                        ? faToggleOn
                                        : faToggleOff
                                }
                                size="2x"
                                onClick={() => handleRememberChange()}
                            />
                        </Form.Group>
                        */}
                        <div className="login-btn-row">
                            <Button
                                variant="dark"
                                type="submit"
                                className="login-button"
                                onSubmit={(event) => handleSubmit(event)}
                            >
                                {loginTexts.signIn}
                            </Button>
                        </div>

                        {/* 
                        <div
                            className="login-form-text-row"
                            onClick={() =>
                                history.push('/mail')
                            }
                        >
                            {loginTexts.forgotPassword}
                        </div>
                        */}
                        <div
                            className="login-form-text-row"
                        >
                            <Link to="/register">{loginTexts.signUp}</Link>
                        </div>
                    </Form>
                </div>
                <div className="about-area">
                    <About />
                </div>
                <Footer messages={footerTexts}></Footer>
            </Col>
        </div>
    );
};

export default Login;
