import React from "react";
import "./Text.scss";

const Text = ({title, content}) => {
    return (
        <div className="login-text">
            <h1>{ title }</h1>
            <p>{ content }</p>
        </div>
    );
}

export default Text;