import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Dam from "../../components/Dam";
import Legend from "../../components/Legend";
import { getAllDams, getDamEvaluation } from "../../services/DamService";
import { redirectUserToLogin } from "../../services/UserService";
import "./styles.scss";

const Home = ({ homeTexts, legend, ...props }) => {
    const history = useHistory();
    const [viewInList, setViewInList] = useState(true);
    const [dams, setDams] = useState([], []);

    const getLastSubmissionDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(homeTexts.dateLocale);
    }

    const fetchAndSetEvaluations = (dams) => {
        const fetchPromises = dams.map(dam => {
            return getDamEvaluation(dam.id).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status == 403)
                        console.log("This submission evaluation was not paid for yet");
                    else if (response.status == 401)
                        redirectUserToLogin()
                    else
                        console.log(response.status, response.statusText);
                }
            })
        });

        Promise.all(fetchPromises).then((responses) => {

            const damsCopy = [...dams];

            damsCopy.forEach((dam) => {
                const validResponse = responses.find(response => typeof response !== "undefined" && response.id == dam.id);
                dam.evaluation = validResponse ? validResponse.result : -1;
                if (validResponse) {
                    dam.ci = {};
                    if (validResponse.ci && validResponse.ci.length > 0) {
                        dam.ci.min = validResponse.ci[0];
                        dam.ci.max = validResponse.ci[1];
                    }
                }
            })

            setDams(damsCopy);
        })
    }

    useEffect(() => {
        getAllDams().then((response) => {
            if (response.ok)
                return response.json();
            else if (response.status == 401)
                redirectUserToLogin();
            else
                console.log(response.status, response.statusText);
        }).then((response) => {
            const dams = response.map(dam => {
                return {
                    id: dam.id,
                    messages: homeTexts.damActions,
                    name: dam.structure_information.structure_name,
                    date: dam.structure_information.submission_date,
                    evaluation: -1,
                }
            })

            dams.reverse();
            fetchAndSetEvaluations(dams);
        }).catch((error) => {
            console.log(error);
        })
    }, [])

    return (
        <>
            <div className="home">
                <div className="home-page">
                    <div className="buttons">
                        <Button
                            className="primary-button"
                            onClick={() =>
                                history.push('/edit/')
                            }
                        >
                            {homeTexts.newDam}
                        </Button>
                        <div className="buttons-view">
                            <div
                                className={`view-button view-list ${viewInList ? "view-active" : ""
                                    }`}
                                onClick={() => setViewInList(true)}
                            >
                                <div className="a block" />
                                <div className="a block" />
                                <div className="a block" />
                            </div>
                            <div
                                className={`view-button view-blocks ${!viewInList ? "view-active" : ""
                                    }`}
                                onClick={() => setViewInList(false)}
                            >
                                <div className="b block" />
                                <div className="b block" />
                                <div className="b block" />
                                <div className="b block" />
                                <div className="b block" />
                                <div className="b block" />
                            </div>
                        </div>
                    </div>
                    <div className={`dams  ${!viewInList ? "view-in-blocks" : ""}`}>
                        {dams.map((dam) =>
                            <Dam
                                key={dam.id}
                                id={dam.id}
                                messages={dam.messages}
                                name={dam.name}
                                date={getLastSubmissionDate(dam.date)}
                                evaluation={dam.evaluation}
                                result={homeTexts.result}
                                upperMargin={homeTexts.upperMargin}
                                lowerMargin={homeTexts.lowerMargin}
                                confirmation={homeTexts.confirmation}
                                ci={dam.ci}
                                {...props}
                            />)}
                    </div>
                </div>

            </div>
            <Legend legend={legend} />
        </>
    );
};

export default Home;
