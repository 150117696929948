import "./InputDefault.scss";
import React from "react";

const InputDefault = ({ type, placeholder, title, onChange, disabled = false, required = true, question, isEmpty, dam }) => {
  return (
    <div className="input-text" title={title}>
      <div className="input-block">
        {type === 'number' && (
          <input type="number" min="0.0" step="0.01" onWheel={(e) => e.target.blur()} disabled={disabled} required={required} onChange={(e) => onChange(question.id, e.target.value)} value={isEmpty(question.id.split("."), 0, dam)} />
        )}

        {type !== 'number' && (
          <input type={type} disabled={disabled} required={required} onChange={(e) => onChange(question.id, e.target.value)} value={isEmpty(question.id.split("."), 0, dam)} />
        )}

        {!disabled && placeholder !== undefined && <span className="placeholder" style={isEmpty(question.id.split("."), 0, dam) !== "" ? { backgroundColor: "white" } : {}}>{placeholder}</span>}

      </div>
    </div>
  );
};

export default InputDefault;