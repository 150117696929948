import React from "react";
import AnalyzeButton from "../../assets/img/analyze-button.svg";

const AnalyzeSection = ({ selectedSection, setSelectedSection, number, dam, onSave, analyze, saveDam }) => {

    return (
        <div className="section">
            <input
                type="radio"
                id={`section-${number}`}
                name="section"
                value={number}
                checked={selectedSection === number}
                readOnly
            />
            <label
                className="analyze-option section-option"
                htmlFor="section"
                onClick={() => {
                    saveDam(dam, onSave);
                    setSelectedSection(number);
                }}>
                <img src={AnalyzeButton} />
                {analyze}
            </label>
        </div >
    );
}

export default AnalyzeSection;