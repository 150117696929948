const defaultDam = {
    id: "new",
    classification: "",
    bhq_1: "",
    bhq_2: "",
    bhq_3: "",
    is_there_eap_for_normal_conditions: "",
    is_there_eap_for_flood_conditions: "",
    is_structure_analysis_available: "",
    structure_information: {
        structure_name: "",
        catchment_area: "",
        structure_owner: "",
        structure_operator: "",
        barrage_type: "",
        barrage_material: "",
        height_above_foundation: "",
        downstream_toe_height_above_ground: "",
        downstream_toe_lowest_elevation_above_sea_level: "",
        are_berms_downstream: "",
        berm_height_above_ground: "",
        berm_width: "",
        water_side_dam_slope: "",
        land_side_dam_slope: "",
        storage: "",
        dam_crest_width: ""
    },
    usability_form: {
        max_outflow_spillway_bottom_outlet_design_case_2: "",
        freeboard_height_bhq1_discharge: "",
        freeboard_height_water_level_lower_bridge_bhq2_discharge: "",
        noticeable_problems: [],
        spillway_reduced_flow_cross_section: "",
        spillway_damage_at_conduits_or_channels: "",
        spillway_debris_stop_coarse_screen_palisade_rack_open: "",
        bt_reduced_flow_cross_section: "",
        bt_defects_damages_leaks: "",
        bt_defects_damages_stilling_basin: "",
        bt_sluggishness_or_gate_valve_blocking: "",
        bt_measuring_instrumentation_serviceable: ""
    },
    durability_form: {
        bank_damages_in_reservoir: "",
        es_sliding: "",
        es_cracks: "",
        es_visible_settling: "",
        es_visible_heave_lifting: "",
        es_visible_horizontal_displacements: "",
        es_landside_airside_water_leavings: "",
        es_erosion_waterside_protection_layer: "",
        es_defects_grass_cover_embankment: "",
        es_wooden_vegetation_no_additional_cross_section: "",
        es_reduced_inspection_due_to_vegetation: "",
        es_burrowing_animals: "",
        cm_cracks: "",
        cm_visible_settling: "",
        cm_visible_horizontal_displacements: "",
        cm_visible_inclination_changes: "",
        cm_spalling_flaking: "",
        cm_landside_airside_water_leavings: "",
        cm_erosion_elutriation_scouring: "",
        cm_defects: ""
    },
    location: {
        easting: "",
        northing: "",
        place: "",
        street: "",
        zip_code: "",
        house_number: "",
        impounded_water: ""
    }
}

export default defaultDam;