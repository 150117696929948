import Input from "../Input";

const Question = ({ onChange, question, dam, hasSaveFailed, disabled, ...props }) => {

    const isQuestionAnswered = (question) => {

        const answerContent = question.id ? isEmpty(question.id.split("."), 0, dam) : "";
        const answerFilled = answerContent !== null && answerContent !== undefined && answerContent !== "" && (question.type === "checkbox" || answerContent !== "unknown");        
        const exceptions = question.type === "checkbox";

        let isAnswered = answerFilled && !exceptions;

        if (question.type === "form") {
            const formFilled = question.questions.every((q) => {
                const qContent = q.id ? isEmpty(q.id.split("."), 0, dam) : "";
                const qFilled = qContent !== null && qContent !== undefined && qContent !== "" && (q.type === "checkbox" || qContent !== "unknown");
                return qFilled;
            });
            isAnswered = formFilled && !exceptions;
        }

        return isAnswered;
    }

    const isEmpty = (path, i, content) => {
        if (i < path.length - 1) {
            return isEmpty(path, i + 1, content[path[i]]);
        }

        return content[path[i]];
    }

    return (
        <div className="question"
            style={isQuestionAnswered(question)
                ? { backgroundColor: "white" }
                : {}
            }
        >
            {(question.title || question.description) && (
                <div className="question-description">
                    <h1 dangerouslySetInnerHTML={{ __html: question.title }} />
                    {question.description
                        ? <>
                            <br></br>
                            <span className="description" dangerouslySetInnerHTML={{ __html: question.description }} />
                        </>
                        : <></>}
                </div>
            )}
            <Input {...props} disabled={disabled} question={question} onChange={onChange} dam={dam} hasSaveFailed={hasSaveFailed} />
        </div>
    );
}

export default Question;