import React from "react";

function PayPal({height="140.375", width="526.775"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 526.775 140.375"
      xmlSpace="preserve"
    >
      <g transform="matrix(1.25 0 0 -1.25 0 140.375)">
        <g
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          transform="scale(.1)"
        >
          <path
            fill="#283b82"
            d="M505.703 1122.93H177.922c-22.434 0-41.508-16.3-45.008-38.45L.344 243.961c-2.637-16.578 10.21-31.535 27.031-31.535h156.488c22.43 0 41.504 16.293 45.004 38.484l35.754 226.699c3.453 22.196 22.574 38.493 44.957 38.493h103.766c215.918 0 340.531 104.484 373.078 311.535 14.664 90.586.621 161.758-41.797 211.603-46.586 54.74-129.215 83.69-238.922 83.69zm37.817-306.989c-17.926-117.617-107.793-117.617-194.688-117.617h-49.461L334.07 917.98c2.063 13.278 13.563 23.055 26.985 23.055h22.668c59.191 0 115.031 0 143.882-33.738 17.208-20.133 22.481-50.039 15.915-91.356"
          ></path>
          <path
            fill="#283b82"
            d="M1485.5 819.727h-156.96c-13.37 0-24.92-9.778-26.99-23.055l-6.94-43.902-10.98 15.914c-33.98 49.32-109.76 65.804-185.39 65.804-173.451 0-321.599-131.371-350.451-315.656-15-91.926 6.328-179.828 58.473-241.125 47.832-56.363 116.273-79.848 197.708-79.848 139.76 0 217.26 89.86 217.26 89.86l-7-43.614c-2.64-16.679 10.21-31.632 26.94-31.632h141.38c22.48 0 41.46 16.297 45.01 38.484l84.83 537.234c2.69 16.536-10.11 31.536-26.89 31.536zM1266.71 514.23c-15.14-89.671-86.32-149.875-177.09-149.875-45.58 0-82.01 14.622-105.401 42.325-23.196 27.511-32.016 66.668-24.633 110.285 14.137 88.906 86.514 151.066 175.894 151.066 44.58 0 80.81-14.808 104.68-42.746 23.92-28.23 33.4-67.629 26.55-111.055"
          ></path>
          <path
            fill="#283b82"
            d="M2321.47 819.727h-157.73c-15.05 0-29.19-7.477-37.72-19.989l-217.55-320.449-92.21 307.941c-5.8 19.27-23.58 32.497-43.71 32.497h-155c-18.84 0-31.92-18.403-25.93-36.137l173.74-509.863-163.34-230.586C1589.17 24.98 1602.11 0 1624.31 0h157.54c14.95 0 28.95 7.29 37.43 19.559l524.62 757.269c12.56 18.121-.33 42.899-22.43 42.899"
          ></path>
          <path
            fill="#469bdb"
            d="M2843.7 1122.93h-327.83c-22.38 0-41.46-16.3-44.96-38.45l-132.57-840.519c-2.63-16.578 10.21-31.535 26.94-31.535h168.23c15.62 0 29 11.402 31.44 26.933l37.62 238.25c3.45 22.196 22.58 38.493 44.96 38.493h103.72c215.96 0 340.53 104.484 373.12 311.535 14.72 90.586.58 161.758-41.84 211.603-46.54 54.74-129.12 83.69-238.83 83.69zm37.82-306.989c-17.88-117.617-107.74-117.617-194.69-117.617h-49.41l34.75 219.656c2.06 13.278 13.46 23.055 26.93 23.055h22.67c59.15 0 115.03 0 143.88-33.738 17.21-20.133 22.43-50.039 15.87-91.356"
          ></path>
          <path
            fill="#469bdb"
            d="M3823.46 819.727h-156.87c-13.47 0-24.93-9.778-26.94-23.055l-6.95-43.902-11.02 15.914c-33.98 49.32-109.71 65.804-185.34 65.804-173.46 0-321.55-131.371-350.41-315.656-14.95-91.926 6.28-179.828 58.43-241.125 47.93-56.363 116.27-79.848 197.7-79.848 139.76 0 217.26 89.86 217.26 89.86l-7-43.614c-2.63-16.679 10.21-31.632 27.04-31.632h141.34c22.38 0 41.46 16.297 44.96 38.484l84.88 537.234c2.58 16.536-10.26 31.536-27.08 31.536zm-218.8-305.497c-15.05-89.671-86.32-149.875-177.09-149.875-45.49 0-82.01 14.622-105.4 42.325-23.19 27.511-31.92 66.668-24.63 110.285 14.23 88.906 86.51 151.066 175.9 151.066 44.57 0 80.8-14.808 104.67-42.746 24.01-28.23 33.5-67.629 26.55-111.055"
          ></path>
          <path
            fill="#469bdb"
            d="M4008.51 1099.87l-134.54-855.909c-2.63-16.578 10.21-31.535 26.94-31.535h135.25c22.48 0 41.56 16.293 45.01 38.484l132.66 840.47c2.64 16.59-10.2 31.59-26.93 31.59h-151.46c-13.37-.04-24.87-9.83-26.93-23.1"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default PayPal;
