import React, { useState } from "react";
import './styles.scss';

const Legend = ({ legend }) => {
    const [showLegend, setShowLegend] = useState(false);

    return (
        <div className="home-legend">
            <div className="home-legend-content">
                <div className="legend-button" onClick={() => setShowLegend(!showLegend)}>
                    <div>
                        <div className="blocks">
                            <div className="evaluation level-1"></div>
                            <div className="evaluation level-2"></div>
                            <div className="evaluation level-3"></div>
                            <div className="evaluation level-4"></div>
                            <div className="evaluation level-5"></div>
                        </div>
                        <p>{legend.title}</p>
                    </div>
                </div>
                {showLegend && (
                    <div className="legend-content">
                        <div className="legend-container">
                            <div className="legend">
                                <div className={"evaluation level-1"}></div>
                                <h3 className="legend-label number">1</h3>
                                <p className="legend-label">{legend.eval1}</p>
                            </div>

                            <div className="legend">
                                <div className={"evaluation level-2"}></div>
                                <h3 className="legend-label number">2</h3>
                                <p className="legend-label">{legend.eval2}</p>
                            </div>

                            <div className="legend">
                                <div className={"evaluation level-3"}></div>
                                <h3 className="legend-label number">3</h3>
                                <p className="legend-label">{legend.eval3}</p>
                            </div>

                            <div className="legend">
                                <div className={"evaluation level-4"}></div>
                                <h3 className="legend-label number">4</h3>
                                <p className="legend-label">{legend.eval4}</p>
                            </div>

                            <div className="legend">
                                <div className={"evaluation level-5"}></div>
                                <h3 className="legend-label number">5</h3>
                                <p className="legend-label">{legend.eval5}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Legend;