import React from "react";

const Section = ({ selectedSection, setSelectedSection, number }) => {
    return (
        <div className="section">
            <input
                type="radio"
                id={`section-${number}`}
                name="section"
                value={number}
                readOnly
            />
            <label style={selectedSection == number ? {backgroundColor: "var(--terciary-bg)", color: "var(--secondary-text)"} : {}} className="section-option" htmlFor="section" onClick={() => setSelectedSection(number)}>{number}
            </label>
        </div>
    );
}

export default Section;