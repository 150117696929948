import axios from "axios";

export async function createDam(dam) {

    console.log("POSTing dam to core", dam);
    console.log("POSTing dam to core (stringify)", JSON.stringify(dam));

    return await fetch(`${process.env.REACT_APP_ZASA_CORE}/submissions`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Token": localStorage.getItem("token"),
        },
        body: JSON.stringify(dam),
    });
}

export async function updateDam(dam) {

    return await fetch(`${process.env.REACT_APP_ZASA_CORE}/submissions/` + dam.id, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Access-Token": localStorage.getItem("token"),
        },
        body: JSON.stringify(dam),
    });
}

export async function getAllDams() {

    return await fetch(`${process.env.REACT_APP_ZASA_CORE}/submissions`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Access-Token": localStorage.getItem("token"),
        },
    });
}

export async function getDam(damId) {

    return await fetch(`${process.env.REACT_APP_ZASA_CORE}/submissions/` + damId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Access-Token": localStorage.getItem("token"),
        },
    });
}

export async function updateDamAsPaidFor(damId, paypalOrderId, paypalOrderSender) {
    const Service = axios.create();
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Access-Token": localStorage.getItem("token")
        }
    };

    const paypalInfo = {
        "paypal_order_id": paypalOrderId,
        "paypal_order_sender": paypalOrderSender
    }

    return await Service.put(`${process.env.REACT_APP_ZASA_CORE}/submissions/${damId}`, JSON.stringify(paypalInfo), config);
}

export function updateAsPaidAndRedirect(id, orderId, customerEmail) {
    updateDamAsPaidFor(id, orderId, customerEmail).then((response) => {
        if (!response.ok) {
            console.log(response.status, response.statusText);
            location.href = "/"
        }
    }).catch((error) => {
        console.log(error);
    });
}

export async function getDamEvaluation(damId) {

    return await fetch(`${process.env.REACT_APP_ZASA_CORE}/submissions/` + damId + "/evaluation", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Access-Token": localStorage.getItem("token"),
        },
    });
}

export function parseCheckboxesToBoolean(dam) {
    const damForm = Object.keys(dam);

    const propsToDelete = [];

    damForm.forEach((prop) => {
        if (typeof dam[prop] === 'object'
            && dam[prop] !== null
            && prop !== "paypal_order_id"
            && prop !== "paypal_order_sender") {

            console.log(prop, dam[prop])
            parseCheckboxesToBoolean(dam[prop]);
        } else {
            if (dam[prop] === "true") {
                dam[prop] = true;
            } else if (dam[prop] === "false") {
                dam[prop] = false;
            } else if (dam[prop] === "null" || dam[prop] === "unknown") {
                dam[prop] = null;
            } else if (dam[prop] === "") {
                propsToDelete.push(prop);
            }
        }
    });

    propsToDelete.forEach((prop) => {
        delete dam[prop];
    })
}

export function checkIfUnknownOptionsWereChecked(dam) {
    if (dam.durability_form.es_sliding !== "") {
        dam.durability_form.has_filled_es_sliding = true;
    }
    if (dam.durability_form.es_burrowing_animals !== "") {
        dam.durability_form.has_filled_es_burrowing_animals = true;
    }
    if (dam.durability_form.cm_visible_inclination_changes !== "") {
        dam.durability_form.has_filled_cm_visible_inclination_changes = true;
    }
    if (dam.usability_form.spillway_reduced_flow_cross_section !== "") {
        dam.usability_form.has_filled_spillway_reduced_flow_cross_section = true;
    }
    if (dam.usability_form.spillway_damage_at_conduits_or_channels !== "") {
        dam.usability_form.has_filled_spillway_damage_at_conduits_or_channels = true;
    }
    if (dam.usability_form.bt_reduced_flow_cross_section !== "") {
        dam.usability_form.has_filled_bt_reduced_flow_cross_section = true;
    }
}

export function parseCheckboxesToString(dam) {
    const damForm = Object.keys(dam);

    damForm.forEach((prop) => {
        if (!Array.isArray(dam[prop])) {
            if (typeof dam[prop] === 'object' && dam[prop]) {
                parseCheckboxesToString(dam[prop]);
            } else {
                if (dam[prop] === true) {
                    dam[prop] = "true";
                } else if (dam[prop] === false) {
                    dam[prop] = "false";
                } else if (dam[prop] === null && prop !== "location"
                    && prop !== "paypal_order_id"
                    && prop !== "paypal_order_sender"
                    && prop !== "structure_owner"
                    && prop !== "structure_operator"
                    && prop !== "impounded_water"
                    && prop !== "house_number"
                    && prop !== "street"
                    && prop !== "place"
                    && prop !== "zip_code") {

                    dam[prop] = "unknown";
                }
            }
        }
    });
}

export function checkIfUnknownOptionsNeedToBeConverted(dam) {
    if (dam.durability_form.has_filled_es_sliding === "false")
        dam.durability_form.es_sliding = "";

    if (dam.durability_form.has_filled_es_burrowing_animals === "false")
        dam.durability_form.es_burrowing_animals = "";

    if (dam.durability_form.has_filled_cm_visible_inclination_changes === "false")
        dam.durability_form.cm_visible_inclination_changes = "";

    if (dam.usability_form.has_filled_spillway_reduced_flow_cross_section === "false")
        dam.usability_form.spillway_reduced_flow_cross_section = "";

    if (dam.usability_form.has_filled_spillway_damage_at_conduits_or_channels === "false")
        dam.usability_form.spillway_damage_at_conduits_or_channels = "";

    if (dam.usability_form.has_filled_bt_reduced_flow_cross_section === "false")
        dam.usability_form.bt_reduced_flow_cross_section = "";
}