import React from "react";
import './Select.scss';

const Select = ({ question, dam, onChange, isEmpty, disabled=false }) => {
    return (
        <div className="select-option">
            <input
                type="radio"
                id={question.value}
                name={question.name} value={question.value}
                onChange={(e) => onChange(question.name, e.target.value)}
                checked={isEmpty(question.name.split("."), 0, dam) === question.value.toString()}
                disabled={disabled}
            />
            <label
                htmlFor={question.value}
            >
                {question.label}
            </label>
        </div>
    );
}

export default Select;