import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import SwitchLanguage from "../SwitchLanguage";
import GermanZasaLogo from '../../assets/img/de-zasa-horizontal.svg';
import EnglishZasaLogo from '../../assets/img/en-zasa-horizontal.svg';
import "./styles.scss";
import { useLocation } from "react-router-dom";

const Navigator = ({ locale, setLocale, setLang, navTexts }) => {
    const location = useLocation();
    let paths = location.pathname.split("/");
    let currentPage = paths[paths.length - 1];

    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={`nav ${currentPage === "edit" ? "nav-limit": ""}`}
            style={localStorage.getItem("token") ? { backgroundColor: "var(--navbar-login)", position: "fixed" } : {position: "fixed"}}
        >
            <Container style={{ display: "flex", justifyContent: "space-between" }}>
                <Navbar.Brand href="/">
                    {locale === "de"
                        ? <img
                            src={GermanZasaLogo}
                            height="54px"
                            className="d-inline-block align-top"
                        />
                        : <img
                            src={EnglishZasaLogo}
                            height="54px"
                            className="d-inline-block align-top"
                        />}
                </Navbar.Brand>

                <div style={{ display: "flex", justifyContent: "left" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <SwitchLanguage locale={locale} setLocale={setLocale} setLang={setLang} />
                        <Navbar.Toggle className="justify-content-end" aria-controls="responsive-navbar-nav" />
                    </div>
                </div>

                <Navbar.Collapse id="responsive-navbar-nav" style={localStorage.getItem("token") ? { backgroundColor: "var(--navbar-login)" } : {}}>
                    <Nav className="justify-content-end" style={{ width: "fit-content" }}>
                        <Nav.Link href="/faq">FAQ</Nav.Link>
                        <Nav.Link href="/disclaimer">{navTexts.disclaimer}</Nav.Link>
                        {localStorage.getItem("token") && (<Nav.Link href="/login" onClick={() => localStorage.removeItem("token")}>{navTexts.logout}</Nav.Link>)}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigator;