// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import { Redirect } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    if (!localStorage.getItem("token")) {
        return <Redirect to={{ pathname: "/login" }} />;
    }

    return children;
};

export default PrivateRoute;
