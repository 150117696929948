import React, { useState } from "react";
import './styles.scss';
import English from '../../translation/en.json';
import Deutsch from '../../translation/de.json';
import enFlag from '../../assets/img/en-flag.svg';
import deFlag from '../../assets/img/de-flag.svg';

const SwitchLanguage = ({ locale, setLocale, setLang }) => {
    const changeLanguage = (lang) => {
        setLocale(lang);
        switch (lang) {
            case 'en':
                setLang(English);
                break;
            case 'de':
                setLang(Deutsch);
                break;
            default:
                setLang(English);
        }
        return lang === locale ? "active" : "";
    };

    return (
        <div className={`switch-language ${locale}`}>
            <div className={`flag ${'de' === locale ? "active" : ""}`} onClick={() => changeLanguage('de')}>
                <span>DE</span>
                <img src={deFlag} height="32px" />
            </div>
            <div className={`flag ${'en' === locale ? "active" : ""}`} onClick={() => changeLanguage('en')}>
                <img src={enFlag} height="32px" />
                <span>EN</span>
            </div>
        </div>
    );
}

export default SwitchLanguage;