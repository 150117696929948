import React from "react";
import Checkbox from "./Checkbox";
import Form from "./Form";
import Radio from "./Radio";
import Select from "./Select";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { updateAsPaidAndRedirect } from "../../services/DamService";
import InputDefault from "./InputDefault";
import { toast } from "react-toastify";
import { useState } from "react";
import PayPal from '../../assets/img/paypal.js';

const Input = ({ disabled, question, onChange, confirmation, dam, hasSaveFailed }) => {
    const [showFakeButton, setShowFakeButton] = useState(true);

    const onFakeClick = () => {
        toast.info(confirmation, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setShowFakeButton(false);
    };

    const isEmpty = (path, i, content) => {
        if (i < path.length - 1) {
            return isEmpty(path, i + 1, content[path[i]]);
        }

        return content[path[i]];
    }

    if (question.type) {

        if (question.type === 'analyze') {

            return <div style={{
                width: "100%",
                backgroundColor: "#eee",
                display: "",
                textAlign: "center",
                justifyContent: "center",
                paddingTop: "8px"
            }}>

                {hasSaveFailed
                    ? <></>
                    : <>
                        {showFakeButton && (
                            <div style={{
                                cursor: "pointer",
                                height: "100%",
                                paddingTop: "8px",
                                paddingBottom: "16px"
                            }} onClick={() => onFakeClick()}>
                                <PayPal height="24" />
                            </div>
                        )}
                        {!showFakeButton && (
                            <PayPalButtons
                                style={{
                                    "color": "silver",
                                    "label": "paypal",
                                    width: "max-content"
                                }}
                                fundingSource="paypal"
                                forceReRender={[dam, hasSaveFailed]}
                                disabled={hasSaveFailed}
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [
                                            {
                                                amount: {
                                                    currency_code: "EUR",
                                                    value: 19,
                                                },
                                                payee: {
                                                    email_address: "damsafety23@gmail.com"
                                                }
                                            },
                                        ],
                                    });
                                }}
                                onApprove={(data, actions) => {
                                    return actions.order.capture().then((details) => {
                                        const emailAddress = details.payer.email_address;
                                        const orderId = details.id;
                                        updateAsPaidAndRedirect(dam.id, emailAddress, orderId);
                                    });
                                }}
                            />)}
                    </>
                }
            </div>
        }
        if (question.unit) {
            return (
                <div className="question-input">
                    <InputDefault disabled={disabled} type={question.type} question={question} dam={dam} onChange={onChange} isEmpty={isEmpty} placeholder={question.unit} />
                </div>
            )
        } else if (question.type === 'number') {
            return (
                <div className="question-input">
                    <InputDefault type={question.type} disabled={disabled} question={question} dam={dam} onChange={onChange} isEmpty={isEmpty} />
                </div>
            )
        }

        if (question.type === 'checkbox') {
            return <Checkbox dam={dam} disabled={disabled} getContent={isEmpty} question={question} onChange={onChange} />
        }

        if (question.type === 'select') {
            return <Select question={question} disabled={disabled} dam={dam} onChange={onChange} isEmpty={isEmpty} />;
        }

        if (question.type === 'radio') {
            return <Radio question={question} disabled={disabled} onChange={onChange} isEmpty={isEmpty} dam={dam} />
        }

        if (question.type === 'text') {
            return (
                <div className="question-text">
                    <InputDefault type={question.type} disabled={disabled} question={question} dam={dam} onChange={onChange} isEmpty={isEmpty} />
                </div>
            );
        }

        if (question.type === 'form') {
            return <Form question={question} disabled={disabled} onChange={onChange} dam={dam} />
        }
    }

    return "";
}

export default Input;