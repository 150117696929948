import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import './styles.scss';
import PayPal from '../../assets/img/paypal.js';
import Pen from '../../assets/img/pen.svg';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { updateAsPaidAndRedirect } from "../../services/DamService";
import { useHistory } from "react-router";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const Dam = ({ id, date, name, evaluation, messages, confirmation, result, ci, upperMargin, lowerMargin, locale }) => {
    const history = useHistory();
    const [showFakeButton, setShowFakeButton] = useState(true);

    const getColor = (value) => {
        if (value <= 1.0) return "#6fd461";
        else if (value < 2.0) return "rgb(141, 206, 132)";
        else if (value === 2.0) return "#ABDDA4";
        else if (value < 3.0) return "rgb(213, 238, 178)";
        else if (value === 3.0) return "#FFFFBF";
        else if (value < 4.0) return "rgb(254, 215, 144)";
        else if (value === 4.0) return "#FDAE61";
        else if (value < 5.0) return "rgb(234, 100, 63)";
        else if (value === 5.0) return "#D7191C";

        return "rgb(108, 13, 14)";
    };

    const onFakeClick = () => {
        toast.info(confirmation, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setShowFakeButton(false);
    };

    return (
        <div className="dam">
            <div className="dam-content">
                <Row className="dam-header">
                    <Col>{date}</Col>
                    <Col>
                        <div className="dam-actions">
                            {evaluation > -1
                                ? <div
                                    title={messages.edit}
                                    className="dam-actions-item"
                                    onClick={() => history.push({
                                        pathname: "/view/",
                                        state: { existingId: id }
                                    })
                                    }
                                >
                                    <FontAwesomeIcon icon={faEye} />
                                </div>
                                : <div
                                    title={messages.edit}
                                    className="dam-actions-item"
                                    onClick={() => history.push({
                                        pathname: "/edit/",
                                        state: { existingId: id }
                                    })
                                    }
                                >
                                    <img src={Pen} />
                                </div>
                            }
                            {/* <div title={messages.delete} className="dam-actions-item">
                                <img src={Trash} />
                            </div> */}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="dam-body">
                        {name}
                    </div>
                </Row>
            </div>

            <div className="dam-footer">
                {evaluation > 0
                    ? <div className={`evaluation level-${evaluation} result`}>
                        <div style={{ backgroundColor: getColor(ci?.min) }}><b style={{ color: ci?.min >= 4 ? "white" : "#323232" }}>{lowerMargin}</b> <span style={{ color: ci?.min >= 4 ? "white" : "#323232" }}>{(+ci?.min.toFixed(2))?.toLocaleString(locale)}</span></div>
                        <div style={{ backgroundColor: getColor(evaluation) }}><b style={{ color: evaluation >= 4 ? "white" : "#323232" }}>{result}</b> <span style={{ color: evaluation >= 4 ? "white" : "#323232" }}>{(+evaluation.toFixed(2))?.toLocaleString(locale)}</span></div>
                        <div style={{ backgroundColor: getColor(ci?.max) }}><b style={{ color: ci?.max >= 4 ? "white" : "#323232" }}>{upperMargin}</b> <span style={{ color: ci?.max >= 4 ? "white" : "#323232" }}>{(+ci?.max.toFixed(2))?.toLocaleString(locale)}</span></div>
                    </div>
                    : <div style={{
                        width: "100%",
                        backgroundColor: "#eee",
                        display: "",
                        textAlign: "center",
                        justifyContent: "center",
                        height: "28px"
                    }} className={`evaluation level-${evaluation}`}>
                        {showFakeButton && (
                            <div style={{
                                cursor: "pointer",
                                height: "100%"
                            }} onClick={() => onFakeClick()}>
                                <PayPal height="16" />
                            </div>
                        )}
                        {!showFakeButton && (
                            <PayPalButtons
                                style={{
                                    "color": "silver",
                                    "layout": "horizontal",
                                    "label": "paypal",
                                    "tagline": false,
                                    "height": 28
                                }}
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [
                                            {
                                                amount: {
                                                    currency_code: "EUR",
                                                    value: 19,
                                                },
                                                payee: {
                                                    email_address: "damsafety23@gmail.com"
                                                }
                                            },
                                        ],
                                    });
                                }}

                                onApprove={(data, actions) => {
                                    return actions.order.capture().then((details) => {
                                        const emailAddress = details.payer.email_address;
                                        const orderId = details.id;
                                        updateAsPaidAndRedirect(id, emailAddress, orderId);
                                    });
                                }}
                            />
                        )}
                    </div>}
            </div>
        </div >
    )
}

export default Dam;