import React, { useEffect, useState } from "react";
import Sections from "../Sections";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createDam, updateDam, parseCheckboxesToBoolean, checkIfUnknownOptionsWereChecked } from "../../services/DamService";
import { redirectUserToLogin } from "../../services/UserService";
import { faCheck, faChevronLeft, faCircleChevronLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import Question from "./Question";
import { Route, Switch, useHistory, useLocation } from "react-router";
import { HashRouter } from "react-router-dom";

const Questions = ({ setShowName, dam, form, onChange, onSave, onUpdate, isComplete, ...props }) => {
    const location = useLocation();
    const [selectedSection, setSelectedSection] = useState(1);
    const [dateFormat, setDateFormat] = useState(localStorage.getItem("last-update") !== undefined ? new Date(localStorage.getItem("last-update")) : new Date());
    const [hasSaveFailed, setSaveFailed] = useState(true);
    const history = useHistory();

    useEffect(() => {
        const number = location.hash.split("/")[1];
        if (selectedSection !== number) {
            setSelectedSection(number);
        }
    }, [location]);


    const getLastSavedDateTime = (dateFormat) => {
        return dateFormat.toLocaleDateString(form.dateLocale) + " - "
            + dateFormat.toLocaleTimeString(form.dateLocale, { hour: '2-digit', minute: '2-digit' });
    }

    const areBermsDownstream = (dam) => {
        return (dam.structure_information.are_berms_downstream === "true"
            || dam.structure_information.are_berms_downstream === true);
    }

    useEffect(() => {
        localStorage.setItem(dam.id, JSON.stringify(dam));
        localStorage.setItem("last-update-" + dam.id, new Date());
        setDateFormat(new Date(localStorage.getItem("last-update-" + dam.id)));
    }, [dam]);

    const createNewDam = (dam, onSave) => {
        console.log("1", { ...dam });
        checkIfUnknownOptionsWereChecked(dam);
        console.log("2", { ...dam });
        parseCheckboxesToBoolean(dam);
        console.log("3", dam);
        //removeLocationIfEmpty(dam);

        createDam(dam).then((response) => {
            if (!response.ok) {
                if (response.status == 401)
                    redirectUserToLogin();
                else if (response.status == 400) {
                    toast.warn(form.errorMessage, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setSaveFailed(true);
                    console.log(response.status, response.statusText);
                    return response.json();
                } else {
                    toast.warn(form.errorMessage, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setSaveFailed(true);
                    console.log(response.status, response.statusText);
                    return response.json();
                }
            } else {
                return response.json();
            }
        }).then((response) => {
            if (response) {
                if (response.message)
                    console.log(response.message);
                else if (response.id) {
                    setSaveFailed(false);
                    onSave(response.id);
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const updateExistingDam = (dam, onUpdate) => {
        checkIfUnknownOptionsWereChecked(dam);
        console.log("2", { ...dam });
        parseCheckboxesToBoolean(dam);

        updateDam(dam).then((response) => {
            if (!response.ok) {
                if (response.status == 401)
                    redirectUserToLogin();
                else if (response.status == 400) {
                    toast.warn(form.errorMessage, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setSaveFailed(true);
                    console.log(response.status, response.statusText);
                    return response.json();
                } else {
                    toast.warn(form.errorMessage, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setSaveFailed(true);
                    console.log(response.status, response.statusText);
                    return response.json();
                }
            } else {
                setSaveFailed(false);
                onUpdate();
            }
        }).then((response) => {
            if (response)
                console.log(response.message);
        }).catch((error) => {
            console.log(error);
        });
    }

    const saveDam = (dam, onSave) => {
        const damCopy = structuredClone(dam);

        if (damCopy.id === "new") {
            createNewDam(damCopy, onSave);
        } else
            updateExistingDam(damCopy, onUpdate);
    }

    const asks = {};

    form.questions.map((question, index) => {

        // only show questions `berm_height_above_ground` and `berm_width` 
        // if question `are_berms_downstream` is filled with true
        if ((question.id !== "structure_information.berm_height_above_ground"
            && question.id !== "structure_information.berm_width")
            || areBermsDownstream(dam)) {

            if (asks[question.section] === undefined) asks[question.section] = [];

            asks[question.section].push(
                <div key={index} section={question.section}>
                    <Question {...props} disabled={location.pathname === "/view/"} question={question} onChange={onChange} dam={dam} hasSaveFailed={hasSaveFailed} />
                </div>
            );
        }
    });

    const onChangeSection = (number) => {
        if (number < selectedSection)
            document.getElementById('sections-container').scrollLeft -= 177;
        else
            document.getElementById('sections-container').scrollLeft += 177;

        history.push(`${location.pathname}#/${number}`);
    }

    return (
        <div className="page">
            <div className="page-content">
                <div className="page-status">
                    <span onClick={() => setShowName(true)} className="back-button">
                        <FontAwesomeIcon icon={faChevronLeft} />
                        {form.buttons.backButton}
                    </span>
                    <span className="save-status">
                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: "10px" }} />
                        {dateFormat === null
                            ? dateFormat
                            : form.placeholders.saved + " " + getLastSavedDateTime(dateFormat)
                        }
                    </span>
                </div>

                <Sections selectedSection={selectedSection} setSelectedSection={onChangeSection}
                    isComplete={isComplete} dam={dam} onSave={onSave}
                    analyze={form.buttons.analyzeSection} saveDam={saveDam} />
                <div className="questions">
                    <HashRouter basename={process.env.PUBLIC_URL}>
                        <Switch>
                            <Route path="/:selectedSection">
                                {asks[selectedSection]}
                            </Route>
                        </Switch>
                    </HashRouter>
                </div>

                <div className="page-nav">
                    <span
                        onClick={() => {
                            if (selectedSection > 1) {
                                onChangeSection(selectedSection - 1);
                                window.scrollTo(0, 40);
                            }
                        }}
                        style={selectedSection <= 1 ? { cursor: "default" } : {}}
                        className="nav-button">
                        <FontAwesomeIcon icon={faCircleChevronLeft} style={selectedSection <= 1 ? { color: "grey" } : {}} />
                    </span>
                    <span
                        onClick={() => {
                            if (selectedSection < 10 || (selectedSection == 10 && isComplete && location.pathname !== "/view/")) {
                                onChangeSection(+selectedSection + 1)
                                window.scrollTo(0, 40);
                                if (selectedSection == 10 && isComplete)
                                    saveDam(dam, onSave);
                            }
                        }}
                        style={selectedSection >= 11 || (selectedSection == 10 && !isComplete) ? { cursor: "default" } : {}}
                        className="nav-button">
                        <FontAwesomeIcon
                            icon={faCircleChevronRight}
                            style={selectedSection >= 11 || (selectedSection == 10 && (!isComplete || location.pathname === "/view/")) ? { color: "grey" } : {}}
                        />
                    </span>
                </div>
            </div>
        </div >
    );
}

export default Questions;