import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretUp,
    faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import './styles.scss';

const Question = ({ question, answer }) => {
    const [show, setShow] = useState(false);
    return (
        <div className="question">
            <div className="question-title" onClick={() => setShow(!show)}>
                <div className="question-icon">
                    <FontAwesomeIcon
                        icon={show ? faCaretUp : faCaretDown}
                        size="2x"
                    />
                </div>
                {question}
            </div>
            <div className={`question-answer ${show ? 'question-active' : ''}`}>{answer}</div>
        </div>
    );
}

const FAQ = ({ messages }) => {
    const questions = messages.questions.map((question) => <Question question={question.title} answer={question.answer} />);

    return (
        <div className="faq">
            <div className="faq-content">
            <h1>FAQ</h1>
            <div className="faq-questions">
                {questions}
            </div>
            </div>
            
        </div>
    );
}

export default FAQ;