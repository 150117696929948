import React from "react";
import './styles.scss';

const Disclaimer = ({ disclaimer }) => {
    return (
        <div className="disclaimer">
            <div className="disclaimer-content">
                <span className="title">{disclaimer.title}</span>
                <span dangerouslySetInnerHTML={{ __html: disclaimer.description }} />
            </div>
        </div>
    );
}

export default Disclaimer;