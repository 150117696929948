
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { createUser } from '../../services/UserService';
import './styles.scss';

const Register = ({ messages }) => {
    const [registerForm, setRegisterForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: ""
    });

    const history = useHistory();

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (e) => {
        let updatedValue = {};
        updatedValue[e.target.name] = e.target.value;

        setRegisterForm(registerForm => ({
            ...registerForm,
            ...updatedValue
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        createUser(registerForm).then((response) => {
            if (response.ok) {
                history.push('/login');
                return response.json();
            }
            else {
                setErrorMessage(response.message);
                setShowErrorMessage(true);
                return response.json();
            }
        }).then((response) => {
            setErrorMessage(response.message);
            setShowErrorMessage(true);
            localStorage.setItem("token", response.token);
        }).catch((error) => {
            console.log(error)
        });
    }

    return (
        <div className="register-page">
            <div className='register-page-content'>
                <div className='register-row'>
                    <form className='register-form'>
                        {showErrorMessage && (
                            <div className="login-form-error-msg">
                                <span>{messages.logs[errorMessage]}</span>
                                <div className="hide-error-msg-btn" onClick={() => setShowErrorMessage(false)}>×</div>
                            </div>
                        )}

                        <div className='register-form-title'>
                            {messages.register}
                        </div>

                        <input required
                            type="text"
                            className='input-text'
                            name="firstName"
                            placeholder={messages.firstName}
                            value={registerForm.firstName}
                            onChange={(e) => handleChange(e)} />
                        <input required
                            type="text"
                            className='input-text'
                            name="lastName"
                            placeholder={messages.lastName}
                            value={registerForm.lastName}
                            onChange={(e) => handleChange(e)} />
                        <input required
                            type="email"
                            className='input-text'
                            name="email"
                            placeholder={messages.email}
                            value={registerForm.email}
                            onChange={(e) => handleChange(e)} />
                        <input required
                            type="password"
                            className='input-text'
                            name="password"
                            placeholder={messages.password}
                            value={registerForm.password}
                            onChange={(e) => handleChange(e)} />

                        <div className='register-form-buttons'>
                            <span><Link to="/">{messages.back}</Link></span>
                            <button
                                variant="dark"
                                type="submit"

                                onClick={(event) => handleSubmit(event)}
                            >
                                {messages.register}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Register