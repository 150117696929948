import React from "react";
import Input from "./index";
import './Form.scss';

const Form = ({question, dam, onChange, disabled=false}) => {
    const sub_questions = question.questions.map((question, index) =>
        <div key={index} className="sub-question">
            <h1 dangerouslySetInnerHTML={{ __html: question.title }} />
            <span className="description" dangerouslySetInnerHTML={{ __html: question.description }} />
            <Input question={question} dam={dam} onChange={onChange} disabled={disabled}/>
        </div>
    );

    return (
        <div className="sub-questions">
            {sub_questions}
        </div>
    );
}

export default Form;