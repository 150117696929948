import React from "react";
import './Radio.scss';

const Radio = ({ question, onChange, isEmpty, dam, disabled=false }) => {
    const options = question.options.map((option, index) =>
        <div className="radio-option" key={index}>
            <input
                type="radio"
                id={`${question.id}-${option["label"]}`}
                name={question.id} value={option["value"] === null ? undefined : option["value"]}
                onChange={(e) => onChange(question.id, e.target.value)}
                checked={
                    isEmpty(question.id.split("."), 0, dam) === (typeof option["value"] === "boolean"
                        ? option["value"].toString()
                        : option["value"]
                    ) ? "checked" : ""
                }
                disabled={disabled}
            />
            <label
                className="radio-option-label"
                htmlFor={`${question.id}-${option["label"]}`}
            >
                {option["label"]}
            </label>
        </div>
    );

    return (
        <div className={`radio-options ${question.isAboutStructure !== undefined ? "structure" : ""}`}>
            {options}
        </div>
    );
}

export default Radio;