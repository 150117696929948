import Login from "./pages/Login";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Form from "./pages/Form";
import Navigator from "./components/Navbar";
import { IntlProvider } from "react-intl";
import English from "./translation/en.json";
import Deutsch from "./translation/de.json";
import { useEffect, useState } from "react";
import Footer from "./components/Footer";
import FAQ from "./pages/FAQ";
import PrivateRoute from "./components/PrivateRoute";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Register from "./pages/Register";
import Disclaimer from "./pages/Disclaimer";


export default function App() {
    const [locale, setLocale] = useState(localStorage.getItem("locale") || "en");
    const [lang, setLang] = useState(locale === "en" ? English : Deutsch);

    useEffect(() => {
        localStorage.setItem("locale", locale);
    }, [locale]);

    return (
        <IntlProvider messages={lang} locale={locale}>
            <div>
                <ToastContainer />
                <PayPalScriptProvider options={
                    {   //Sandbox PayPal API Key: AVzipLToG8XLF7L9V8NtCHeR4W1-UhMJuzfJUJfMiuvcZALpqE57mG7UQ9z08uHDAeK55MavHVLH93KA
                        "client-id": "AZ8yH1viHL74Nv0Cm59DKNK-eNs7Hf1JqAvbUCzs12dAFH5IzBbbR_XCUuyD6uI_hiH_852rXBMyv9MK",
                        "merchant-id": "damsafety23@gmail.com",
                        "currency": "EUR"
                    }}>
                    <Router>
                        <Navigator
                            setLang={setLang}
                            locale={locale}
                            setLocale={setLocale}
                            navTexts={lang.nav}
                        />
                        <div className="main-content">
                            <Switch>
                                <PrivateRoute exact path="/">
                                    <Home locale={locale} homeTexts={lang.home} legend={lang.footer.legend} />
                                    <Footer />
                                </PrivateRoute>
                                <Route exact path="/login">
                                    <Login loginTexts={lang.login}
                                        footerTexts={lang.footer}
                                        locale={locale} />
                                </Route>
                                <PrivateRoute exact path="/edit/">
                                    <Form confirmation={lang.home.confirmation} form={lang.form} />
                                    <Footer />
                                </PrivateRoute>
                                <PrivateRoute exact path="/view/">
                                    <Form confirmation={lang.home.confirmation} form={lang.form} />
                                    <Footer />
                                </PrivateRoute>
                                <Route exact path="/faq">
                                    <FAQ messages={lang.FAQ} />
                                    <Footer />
                                </Route>
                                <Route exact path="/register">
                                    <Register messages={lang.register} />
                                    <Footer />
                                </Route>
                                <Route exact path="/disclaimer">
                                    <Disclaimer disclaimer={lang.disclaimer} />
                                    <Footer />
                                </Route>
                            </Switch>
                        </div >
                    </Router>
                </PayPalScriptProvider>
            </div >

        </IntlProvider >
    );
}
