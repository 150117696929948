import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';
import './styles.scss';
import './Questions.scss';
import defaultDam from "../../assets/js/DefaultDam"
import Questions from "../../components/Questions";
import { getDam, parseCheckboxesToString, checkIfUnknownOptionsNeedToBeConverted } from "../../services/DamService";
import { redirectUserToLogin } from "../../services/UserService";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = ({ name, showName, setShowName }) => {
    return (
        <div style={showName ? { display: "flex" } : {}} className="nav-edit">
            <div className="nav-edit-content">
                {!showName && <div onClick={() => setShowName(true)}><FontAwesomeIcon style={{ cursor: "pointer" }} icon={faChevronLeft} /></div>}
                <h1>{showName ? "NEW DAM" : name}</h1>
            </div>

        </div>
    )
}

const isDamFormComplete = (currentDam) => {
    let isComplete = true;

    if (currentDam.structure_information.are_berms_downstream === "true"
        || currentDam.structure_information.are_berms_downstream === true) {

        const bermHeightNotFilled = (currentDam.structure_information.berm_height_above_ground === null
            || currentDam.structure_information.berm_height_above_ground === "")
        const bermWidthNotFilled = (currentDam.structure_information.berm_width === null
            || currentDam.structure_information.berm_width === "")

        if (bermHeightNotFilled || bermWidthNotFilled)
            isComplete = false;
    }

    return isComplete;
}

const Name = ({ disabled = false, dam, placeholder, onChange, setShowName, buttons }) => {
    const history = useHistory();

    localStorage.removeItem("name");

    const onSaveDamName = () => {
        localStorage.setItem("name", dam.structure_information.structure_name);
        setShowName(false);
        history.push(location.pathname + '#/1');
    }

    return (
        <div className="name-area">
            <div className="form-content">
                <textarea disabled={disabled} placeholder={placeholder} value={dam.structure_information.structure_name} onChange={(e) => onChange("structure_information.structure_name", e.target.value)} className="reservoir-name" type="text" />
                <div>
                    <button style={dam.structure_information.structure_name === "" ? { backgroundColor: "var(--color-10)" } : {}} disabled={dam.structure_information.structure_name === "" ? true : false} onClick={() => onSaveDamName()}>{buttons.saveButton}</button>
                </div>
                <span className="back" onClick={() =>
                    history.push('/')
                }>{buttons.backButton}</span>
            </div>
        </div>
    );
}

export default function Form({ form, damId, ...props }) {
    const location = useLocation();
    const existingId = location.state ? location.state.existingId : undefined;

    useEffect(() => {
        if (location.hash === "#/") setShowName(true);
        else setShowName(false)
    }, [location])

    const id = damId ? damId : "new";
    const [isComplete, setComplete] = useState(true);
    const [dam, setDam] = useState(localStorage.getItem(id) !== null
        ? JSON.parse(localStorage.getItem(id))
        : defaultDam);

    useEffect(() => {
        const formatAndSetDam = (dam) => {
            const formattedDam = { ...dam };
            if (dam.structure_information.crest_cover_materials) {
                formattedDam.structure_information.crest_cover_materials = Object.values({
                    ...dam.structure_information.crest_cover_materials
                })
            }
            if (dam.structure_information.land_side_slope_cover_materials) {
                formattedDam.structure_information.land_side_slope_cover_materials = Object.values({
                    ...dam.structure_information.land_side_slope_cover_materials
                })
            }
            if (dam.structure_information.water_side_slope_cover_materials) {
                formattedDam.structure_information.water_side_slope_cover_materials = Object.values({
                    ...dam.structure_information.water_side_slope_cover_materials
                })
            }
            if (dam.usability_form.noticeable_problems) {
                formattedDam.usability_form.noticeable_problems = Object.values({
                    ...dam.usability_form.noticeable_problems
                })
            }
            // Needed so the form can show "checked" checkboxes
            parseCheckboxesToString(formattedDam);
            checkIfUnknownOptionsNeedToBeConverted(formattedDam);
            console.log("test", formattedDam.usability_form.bt_reduced_flow_cross_section)

            setDam(formattedDam);
        }

        if (!damId)
            damId = existingId;

        if (damId) {
            getDam(damId).then((response) => {
                if (response.ok)
                    return response.json();
                else if (response.status == 401)
                    redirectUserToLogin();
                else
                    console.log(response.status, response.statusText);
            }).then((response) => {
                formatAndSetDam(response);
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [dam.id]);

    const onChange = (field, value) => {
        let path = field.split(".");
        if (path.length === 2) {
            let property = dam[path[0]];
            property[path[1]] = value;
            setDam({ ...dam, [path[0]]: property });
        } else {
            setDam({ ...dam, [field]: value });
        }

        if (isDamFormComplete(dam)) {
            setComplete(true);
        }
    };

    const onSave = (id) => {
        localStorage.removeItem("new");
        console.log("onSave to storage", dam);
        setDam({ ...dam, id: id });

        toast.success(form.submitMessage.save, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };

    const onUpdate = () => {
        toast.success(form.submitMessage.update, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const [showName, setShowName] = useState(true);

    return (
        <div className="form">
            <Navbar name={dam.structure_information.structure_name} showName={showName} setShowName={setShowName} />
            {showName && (
                <Name disabled={location.pathname === "/view/"} setShowName={setShowName} dam={dam} placeholder={form.placeholders.name} onChange={onChange} buttons={form.buttons} />
            )}

            {!showName && (
                <Questions {...props} setShowName={setShowName} dam={dam} form={form} onChange={onChange} onSave={onSave} onUpdate={onUpdate} isComplete={isComplete} />
            )}
        </div>
    )
};