
export function redirectUserToLogin() {
    localStorage.removeItem("token");
    location.href = "/login"
}

export async function loginUser(email, password) {

    const credentials = {
        email: email,
        password: password,
    };

    return await fetch(`${process.env.REACT_APP_ZASA_CORE}/users/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(credentials),
    });
}

export async function createUser(user) {
    return await fetch(`${process.env.REACT_APP_ZASA_CORE}/users/register`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
    });
}