import React from "react";
import Section from "./Section";
import AnalyzeSection from "./AnalyzeSection"
import './styles.scss';
import { useLocation } from "react-router";

const Sections = ({ setSelectedSection, selectedSection, isComplete, dam, onSave, analyze, saveDam }) => {
    const location = useLocation();

    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const sections = numbers.map((number) =>
        <Section
            key={number}
            number={number}
            setSelectedSection={setSelectedSection}
            selectedSection={selectedSection}
        />
    );

    if (isComplete && location.pathname !== "/view/") {
        sections.push(
            <AnalyzeSection
                key={numbers[numbers.length - 1] + 1}
                number={numbers[numbers.length - 1] + 1}
                setSelectedSection={setSelectedSection}
                selectedSection={selectedSection}
                dam={dam}
                onSave={onSave}
                analyze={analyze}
                saveDam={saveDam}
                readOnly
            />)
    }

    return (
        <div className="sections" id="sections-container">{sections}</div>
    );
}

export default Sections;